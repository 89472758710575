<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.751" x2="0.212" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_feedback" transform="translate(-2442 -2618)">
    <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2442 2618)" width="183"/>
    <path d="M107.5,49.281c0,25.976-24.065,47.031-53.75,47.031a60.872,60.872,0,0,1-15.77-2.057c-3.924,1.989-12.933,5.8-28.09,8.289a1.746,1.746,0,0,1-1.833-2.434,71.578,71.578,0,0,0,5.172-19.929C5,71.922,0,61.106,0,49.281,0,23.3,24.065,2.25,53.75,2.25S107.5,23.3,107.5,49.281Z" data-name="Path 475" fill="url(#linear-gradient)" fill-rule="evenodd" id="Path_475" transform="translate(2480 2656.75)"/>
    <path d="M18.232,1.252l-4.591,9.309-10.273,1.5A2.251,2.251,0,0,0,2.123,15.9L9.555,23.14,7.8,33.37a2.249,2.249,0,0,0,3.262,2.37l9.19-4.83,9.19,4.83A2.25,2.25,0,0,0,32.7,33.37L30.945,23.14,38.377,15.9a2.251,2.251,0,0,0-1.245-3.839l-10.273-1.5L22.268,1.252a2.252,2.252,0,0,0-4.036,0Z" data-name="Icon awesome-star" fill="#fff" id="Icon_awesome-star" transform="translate(2513.5 2686.157)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>